import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import VueMeta from "vue-meta";
// import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

// Vue.use(
//   VueGtag,
//   {
//     config: { id: "G-DQFN934KLX" },
//     // includes: [{ id: "GTM-NNV26H9" }],
//     appName: "Kondo_CPA",
//     pageTrackerScreenviewEnabled: true
//   },
//   router
// );
Vue.use(VueMeta);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount("#app");
